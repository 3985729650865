import React from 'react'

import './styles.scss'

export const Contact = () => {
    return (
        <div className="contact">
            <div className="container">
                <div className="contactForm" data-aos="fade-up">
                    <h1>Get in touch</h1>
                    <div className="address">
                        <div className="address_item">
                            <svg enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m407.579 87.677c-31.073-53.624-86.265-86.385-147.64-87.637-2.62-.054-5.257-.054-7.878 0-61.374 1.252-116.566 34.013-147.64 87.637-31.762 54.812-32.631 120.652-2.325 176.123l126.963 232.387c.057.103.114.206.173.308 5.586 9.709 15.593 15.505 26.77 15.505 11.176 0 21.183-5.797 26.768-15.505.059-.102.116-.205.173-.308l126.963-232.387c30.304-55.471 29.435-121.311-2.327-176.123zm-151.579 144.323c-39.701 0-72-32.299-72-72s32.299-72 72-72 72 32.299 72 72-32.298 72-72 72z" /></g></svg>
                            <b>Hlaváč Business, s.r.o.</b><br /> Nivy Tower, Mlynské Nivy 5, 821 09 Bratislava<br /> Slovak Republic</div>
                        <div className="address_item">
                            <svg id="Layer_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m399.98 402.421c.018-1.072.024-2.147.018-3.224-.43-78.454-64.434-142.599-142.887-143.193-79.91-.606-145.111 64.224-145.111 143.996 0 1.112.013 2.222.038 3.328 1.095 48.019 34.827 89.15 81.4 100.897 20.319 5.125 41.328 7.775 62.562 7.775 21.23 0 42.235-2.649 62.551-7.772 46.901-11.827 80.627-53.444 81.429-101.807z" /><path d="m437.012 74.972c-48.069-48.065-113.036-74.972-181.013-74.972-68.38 0-132.667 26.629-181.019 74.98-48.352 48.352-74.98 112.639-74.98 181.02 0 60.347 21.397 118.944 60.256 165.004 4.528 5.361 9.288 10.549 14.245 15.531 3.463 3.48 9.315.339 8.423-4.489-1.92-10.394-2.924-21.105-2.924-32.046 0-73.03 44.711-135.812 108.198-162.414 3.295-1.381 4.145-5.705 1.56-8.172-19.08-18.214-30.69-44.188-29.699-72.803 1.731-49.986 42.17-90.602 92.149-92.537 54.654-2.117 99.792 41.733 99.792 95.926 0 27.273-11.435 51.921-29.759 69.414-2.584 2.467-1.733 6.791 1.561 8.172 63.487 26.602 108.198 89.384 108.198 162.414 0 10.941-1.004 21.652-2.925 32.045-.892 4.828 4.96 7.969 8.423 4.489 4.958-4.983 9.72-10.172 14.25-15.537 38.855-46.052 60.252-104.649 60.252-164.997 0-68.385-26.631-132.676-74.988-181.028z" /><circle cx="256" cy="160" r="64" /></g></svg>
                            <span>IČO:</span> 50 150 880<br />
                            <span>DIČ:</span> 2120205824<br />
                            <span>IČ DPH:</span> SK2120205824
                        </div>
                        <div className="address_item address_item-email">
                            <svg x="0px" y="0px" width="511.626px" height="511.626px" viewBox="0 0 511.626 511.626">
                                <g>
                                    <g>
                                        <path d="M49.106,178.729c6.472,4.567,25.981,18.131,58.528,40.685c32.548,22.554,57.482,39.92,74.803,52.099
			c1.903,1.335,5.946,4.237,12.131,8.71c6.186,4.476,11.326,8.093,15.416,10.852c4.093,2.758,9.041,5.852,14.849,9.277
			c5.806,3.422,11.279,5.996,16.418,7.7c5.14,1.718,9.898,2.569,14.275,2.569h0.287h0.288c4.377,0,9.137-0.852,14.277-2.569
			c5.137-1.704,10.615-4.281,16.416-7.7c5.804-3.429,10.752-6.52,14.845-9.277c4.093-2.759,9.229-6.376,15.417-10.852
			c6.184-4.477,10.232-7.375,12.135-8.71c17.508-12.179,62.051-43.11,133.615-92.79c13.894-9.703,25.502-21.411,34.827-35.116
			c9.332-13.699,13.993-28.07,13.993-43.105c0-12.564-4.523-23.319-13.565-32.264c-9.041-8.947-19.749-13.418-32.117-13.418H45.679
			c-14.655,0-25.933,4.948-33.832,14.844C3.949,79.562,0,91.934,0,106.779c0,11.991,5.236,24.985,15.703,38.974
			C26.169,159.743,37.307,170.736,49.106,178.729z"/>
                                        <path d="M483.072,209.275c-62.424,42.251-109.824,75.087-142.177,98.501c-10.849,7.991-19.65,14.229-26.409,18.699
			c-6.759,4.473-15.748,9.041-26.98,13.702c-11.228,4.668-21.692,6.995-31.401,6.995h-0.291h-0.287
			c-9.707,0-20.177-2.327-31.405-6.995c-11.228-4.661-20.223-9.229-26.98-13.702c-6.755-4.47-15.559-10.708-26.407-18.699
			c-25.697-18.842-72.995-51.68-141.896-98.501C17.987,202.047,8.375,193.762,0,184.437v226.685c0,12.57,4.471,23.319,13.418,32.265
			c8.945,8.949,19.701,13.422,32.264,13.422h420.266c12.56,0,23.315-4.473,32.261-13.422c8.949-8.949,13.418-19.694,13.418-32.265
			V184.437C503.441,193.569,493.927,201.854,483.072,209.275z"/>
                                    </g>
                                </g>
                            </svg>

                            <a href="mailto:info@hlavac.business">info@hlavac.business</a>
                        </div>
                    </div>
                </div >
            </div >
            <div className="map" />
        </div>
    )
}
